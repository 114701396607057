import * as React from 'react'
import styled from 'styled-components'
import { title, subtitle } from './shared/constants'
import WebinarButtonCircle from './shared/circle-button'
import { lightBlue, navy } from 'shared/colors'
import backgroundImageURI from './img/background'
import { wordpressTablet, wordpressMobile } from './shared/constants'

const Container = styled.div`
	display: flex;
	width: 799px;
	height: 186px;
	align-items: center;
	border-radius: 4px;
	justify-items: center;
	margin: 0 auto;
	background: url(${backgroundImageURI}) no-repeat top right;
	background-color: ${navy};
	${wordpressTablet} {
		width: 590px;
		overflow: hidden;
	}
	${wordpressMobile} {
		height: 300px;
		width: 240px;
		background: url(${backgroundImageURI}) no-repeat bottom 10px left -200px;
		background-size: 600px;
		background-color: ${navy};
	}
`

const Title = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: ${lightBlue};
	width: 290px;
	padding-bottom: 5px;
	letter-spacing: 1px;
	${wordpressTablet} {
		margin-bottom: 5px;
	}
	${wordpressMobile} {
		margin-left: 0;
		padding-top: 20px;
	}
`

const Subtitle = styled.div`
	font-size: 31px;
	font-weight: bold;
	color: white;
	line-height: 1.12;
	width: 375px;
	padding-bottom: 5px;
	${wordpressTablet} {
		font-size: 24px;
		margin-bottom: 20px;
	}
	${wordpressMobile} {
		font-size: 20px;
		width: 180px;
		margin-left: 0;
		margin-top: 7px;
	}
`

const VariableSpacer = styled.div`
	width: 30px;
	${wordpressTablet} {
		width: 24px;
	}
	${wordpressMobile} {
		height: 50px;
	}
`

const ButtonContainer = styled.div`
	z-index: 2;
	float: right;
	padding-right: 25px;
	margin-top: -15px;
	${wordpressMobile} {
		padding-left: 25px;
		padding-top: 150px;
	}
`

const Wording = styled.div`
	width: 420px;
	${wordpressTablet} {
		width: 340px;
	}
	${wordpressMobile} {
		width: 240px;
		padding-left: 24px;
		top: 1px;
		height: 50px;
	}
`

const Content = styled.div`
	z-index: 2;
	width: 100%;
	${wordpressMobile} {
		display: flex;
		height: 100%;
		flex-direction: column;
	}
`

const DesignB: React.FC = () => (
	<Container>
		<VariableSpacer />
		<Content>
			<Wording>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</Wording>
			<ButtonContainer>
				<WebinarButtonCircle />
			</ButtonContainer>
		</Content>
	</Container>
)

export default DesignB
