import * as React from 'react'
import styled from 'styled-components'
import { title, subtitle } from './shared/constants'
import backgroundImageURI from './img/background'
import { lightBlue, navy } from 'shared/colors'
import WebinarButton from './shared/rect-button'
import TradetappImage from './img/tradetapp'
import TradetappMobileImage from './img/mobile-tradetapp'
import { wordpressTablet, wordpressMobile } from './shared/constants'

const Container = styled.div`
	display: flex;
	width: 799px;
	height: 186px;
	overflow: hidden;
	border-radius: 4px;
	justify-items: center;
	margin: 0 auto;
	background: url(${backgroundImageURI}) no-repeat top right;
	background-color: ${navy};
	${wordpressTablet} {
		width: 590px;
		background: url(${backgroundImageURI}) no-repeat left 250px top;
		background-color: ${navy};
	}
	${wordpressMobile} {
		height: 300px;
		width: 240px;
		background: url(${backgroundImageURI}) no-repeat bottom -25px left -55px;
		background-size: 330px;
		background-color: ${navy};
		flex-direction: column;
	}
`

const Title = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: ${lightBlue};
	width: 290px;
	padding-bottom: 5px;
	letter-spacing: 1px;
`

const Subtitle = styled.div`
	font-size: 24px;
	font-weight: bold;
	width: 290px;
	color: white;
	line-height: 1.1;
	margin-bottom: 26px;
	${wordpressMobile} {
		width: 178px;
		font-size: 20px;
	}
`

const Wording = styled.div`
	width: 375px;
	padding-top: 24px;
	padding-left: 24px;
	${wordpressTablet} {
		width: 475px;
	}
`

const ImageContainer = styled.div`
	height: 100%;
	width: 307px;
	${wordpressTablet} {
		width: 242px;
	}
	${wordpressMobile} {
		width: 186px;
	}
`

const ImageWrapper = styled.div`
	margin-bottom: -3px;
	width: 100%;
	${wordpressTablet} {
		margin-top: 5px;
	}
	${wordpressMobile} {
		display: none;
	}
`

const MobileImageWrapper = styled.div`
	display: none;
	${wordpressMobile} {
		display: inherit;
		position: absolute;
		top: 191px;
		margin-left: 15px;
	}
`

const DesignA: React.FC = () => (
	<Container>
		<Wording>
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
			<WebinarButton />
		</Wording>
		<ImageContainer>
			<ImageWrapper>
				<TradetappImage />
			</ImageWrapper>
			<MobileImageWrapper>
				<TradetappMobileImage />
			</MobileImageWrapper>
		</ImageContainer>
	</Container>
)

export default DesignA
