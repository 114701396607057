import * as React from 'react'
import 'components/layout/index.css'
import 'components/layout/font.css'
import DesignA from './design-a'
import DesignB from './design-b'
import DesignC from './design-c'
import styled from 'styled-components'

const DivB = styled.div`
	display: none;
`
const DivC = styled.div`
	display: none;
`
const Container = styled.div`
	justify-content: center;
`

const TtBlogAd: React.FC = () => {
	return (
		<Container
			onClick={() =>
				window.open(
					'http://offer.buildingconnected.com/tradetapp-integration?utm_campaign=BLOG-11.2019-TT_Integration&utm_source=blog&utm_medium=ad',
					'_blank'
				)
			}
		>
			<div id="design_a">
				<DesignA />
			</div>
			<DivB id="design_b">
				<DesignB />
			</DivB>
			<DivC id="design_c">
				<DesignC />
			</DivC>
		</Container>
	)
}

export default TtBlogAd
