import * as React from 'react'
import { Button } from 'views/components'
import styled from 'styled-components'
import { lightBlue } from 'shared/colors'
import Play from 'imgs/icons/play.svg'
import { mobile } from 'shared/media-queries'

const PlayDiv = styled.div`
	margin-top: 7px;
	margin-left: -5px;
`

const Container = styled.div`
	display: flex;
	${mobile} {
		flex-direction: column-reverse;
		align-items: center;
		text-align: center;
	}
`

const TextDiv = styled.div`
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.21px;
	color: white;
	margin-top: 16px;
	margin-right: 20px;
	${mobile} {
		margin-right: 0;
		margin-top: 8px;
	}
`

const StyledButton = styled(Button)`
	width: 56px;
	height: 56px;
	line-height: 56px;
	color: white;
	border-radius: 56px;
	background-color: ${lightBlue};
	:hover: {
		color: ${lightBlue};
	}
	:active: {
		color: ${lightBlue};
	}
	:visited: {
		color: ${lightBlue};
	}
`

interface Props {
	lightBluePrimary?: boolean
}

const WebinarButtonCircle: React.FC<Props> = () => (
	<Container>
		<TextDiv>Watch Webinar</TextDiv>
		<StyledButton
			label={
				<PlayDiv>
					<Play fill={'white'} height="27px" width="31.5px" />
				</PlayDiv>
			}
		/>
	</Container>
)

export default WebinarButtonCircle
