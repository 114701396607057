import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const TradetappImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "tradetapp/blog-ad/img/tradetapp.png" }
				) {
					childImageSharp {
						fixed(height: 110, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fixed={data.fileName.childImageSharp.fixed}
			alt="tradetapp mobile graphic"
		/>
	)
}

export default TradetappImage
