import * as React from 'react'
import { Button } from 'views/components'
import { lightBlue } from 'shared/colors'
import Play from 'imgs/icons/play.svg'
import styled from 'styled-components'

interface Props {
	lightBluePrimary?: boolean
}

const StyledButton = styled(Button)`
	color: ${(props: Props) => (props.lightBluePrimary ? lightBlue : 'white')};
	background-color: ${(props: Props) =>
		props.lightBluePrimary ? 'white' : lightBlue};
	border-radius: 2px;
	height: 36px;
	line-height: 36px;
	:hover: {
		color: ${(props: Props) => (props.lightBluePrimary ? lightBlue : 'white')};
	}
	:active: {
		color: ${(props: Props) => (props.lightBluePrimary ? lightBlue : 'white')};
	}
	:visited: {
		color: ${(props: Props) => (props.lightBluePrimary ? lightBlue : 'white')};
	}
`

const WebinarButtonRect: React.FC<Props> = ({ lightBluePrimary }) => (
	<StyledButton
		label={
			<div>
				<Play
					fill={lightBluePrimary ? lightBlue : 'white'}
					height="13px"
					width="16.5px"
				/>{' '}
				Watch Webinar
			</div>
		}
		lightBluePrimary={lightBluePrimary}
	/>
)

export default WebinarButtonRect
