import * as React from 'react'
import styled from 'styled-components'
import { title, subtitle } from './shared/constants'
import WebinarButtonRect from './shared/rect-button'
import { lightBlue } from 'shared/colors'
import TradetappImage from './img/tradetapp'
import TradetappMobileImage from './img/mobile-tradetapp'
import { wordpressTablet, wordpressMobile } from './shared/constants'

const Container = styled.div`
	display: flex;
	width: 799px;
	height: 186px;
	align-items: center;
	border-radius: 4px;
	justify-items: center;
	margin: 0 auto;
	background-color: ${lightBlue};
	${wordpressTablet} {
		width: 590px;
		overflow: hidden;
	}
	${wordpressMobile} {
		height: 300px;
		width: 240px;
		flex-direction: column-reverse;
	}
`

const ImageContainer = styled.div`
	height: 100%;
	width: 307px;
	${wordpressTablet} {
		width: 260px;
		margin-right: 30px;
	}
	${wordpressMobile} {
		width: 186px;
	}
`

const ImageWrapper = styled.div`
	margin-bottom: -3px;
	width: 100%;
	${wordpressTablet} {
		margin-top: 5px;
		margin-left: -70px;
	}
	${wordpressMobile} {
		display: none;
	}
`

const MobileImageWrapper = styled.div`
	display: none;
	${wordpressMobile} {
		display: inherit;
		position: absolute;
		top: 191px;
		margin-left: 5px;
	}
`

const Title = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: white;
	width: 290px;
	padding-bottom: 5px;
	opacity: 0.7;
	letter-spacing: 1px;
	${wordpressTablet} {
		margin-bottom: 5px;
	}
	${wordpressMobile} {
		margin-left: 0;
		padding-top: 20px;
	}
`

const Subtitle = styled.div`
	font-size: 31px;
	font-weight: bold;
	color: white;
	line-height: 1.1;
	width: 375px;
	padding-bottom: 5px;
	${wordpressTablet} {
		font-size: 24px;
		width: 290px;
		margin-bottom: 20px;
	}
	${wordpressMobile} {
		font-size: 20px;
		width: 180px;
		margin-left: 0;
		margin-top: 7px;
	}
`

const VariableSpacer = styled.div`
	width: 70px;
	${wordpressTablet} {
		width: 15px;
	}
`

const Wording = styled.div`
	width: 420px;
	${wordpressTablet} {
		width: 340px;
	}
	${wordpressMobile} {
		width: 240px;
		padding-left: 29px;
	}
`

const DesignC: React.FC = () => (
	<Container>
		<ImageContainer>
			<ImageWrapper>
				<TradetappImage />
			</ImageWrapper>
			<MobileImageWrapper>
				<TradetappMobileImage />
			</MobileImageWrapper>
		</ImageContainer>
		<VariableSpacer />
		<Wording>
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
			<WebinarButtonRect lightBluePrimary />
		</Wording>
	</Container>
)

export default DesignC
